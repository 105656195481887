e4.bootstrap.dropdown = {
    init: function () {
        // Popper.Defaults.modifiers.computeStyle.enabled = false;
        e4.bootstrap.dropdown.bindEvents();
    },
    bindEvents: function () {
        //Prevent dropdown menu from closing when clicking child nodes.
        jQuery( '.dropdown' ).on( 'click.bs.dropdown.data-api', function ( event ) {
            var jTarget = jQuery( event.target );
            if ( jTarget.closest( '.js-e-dropdown-dismiss' ).length === 0 && jTarget.closest( '[data-toggle="dropdown"],[data-collapse-toggle="true"]' ).length === 0 ) {
                event.stopPropagation();
            }
        } );
    }
};

