e4.scrollToAnchor = {
    init: function () {
        e4.scrollToAnchor.animating = false;
        e4.scrollToAnchor.goToLocationHash();
        e4.scrollToAnchor.bindEvents();
        e4.scrollToAnchor.backToTop.init();
    },
    goToLocationHash: function () {
        if ( e4.util.hash.getCurrentHashStringValue() !== '' && location.hash.indexOf( '/' ) === -1 ) {
            var intScrollTarget = e4.scrollToAnchor.getTarget( location.hash );
            if ( intScrollTarget > -1 ) {
                e4.scrollToAnchor.animate( intScrollTarget, e4.header.hide );
            }
        }
    },
    bindEvents: function () {
        jQuery( 'a[href*="#"]' ).not( 'a[href*="#/"],a[data-toggle],a[data-slide]' ).on( 'click', function ( event ) {
            if ( this.hash === '#top' ) {
                event.preventDefault();
                e4.scrollToAnchor.animate( 0 );
            }
            else if ( location.pathname.replace( /^\//, '' ) === this.pathname.replace( /^\//, '' ) && location.hostname === this.hostname ) {
                event.preventDefault();
                e4.scrollToAnchor.animate( e4.scrollToAnchor.getTarget( this.hash ), e4.header.hide );
            }
        } );
    },
    getTarget: function ( selector ) {
        var jTarget         = jQuery( selector ),
            jTargetSection  = jTarget.closest( '.e-section' ),
            intScrollTarget = -1;
        if ( jTarget.length === 1 ) {
            if ( jTargetSection.hasClass( e4.settings.scrollReveal.selector ) === true ) {
                jTargetSection.addClass( e4.settings.scrollReveal.classReset );
            }
            intScrollTarget = jTarget.offset().top;
            if ( jTargetSection.is( e4.settings.scrollToAnchor.fullscreenElements ) === false ) {
                intScrollTarget -= e4.scrollToAnchor.getNudge();
            }
            jQuery( e4.settings.scrollToAnchor.stickyElements ).each( function () {
                intScrollTarget -= jQuery( this ).height();
            } );
            // TODO: Handle sticky+autohide header/cta
            intScrollTarget = Math.max( intScrollTarget, 0 );
        }
        return intScrollTarget;
    },
    getNudge: function () {
        return jQuery( window ).height() / 100 * e4.settings.scrollToAnchor.nudge;
    },
    animate: function ( intScrollTarget, fnCallback ) {
        if ( intScrollTarget > -1 ) {
            if ( e4.scrollToAnchor.animating === false ) {
                e4.scrollToAnchor.animating = true;
                jQuery( 'html,body' ).animate( {
                    scrollTop: intScrollTarget
                }, Math.floor( Math.abs( jQuery( window ).scrollTop() - intScrollTarget ) * e4.settings.scrollToAnchor.speed ), e4.settings.scrollToAnchor.easing, function () {
                    e4.scrollToAnchor.animating = false;
                    if ( typeof fnCallback === 'function' ) {
                        fnCallback();
                    }
                } );
            }
        }
        else {
            if ( typeof fnCallback === 'function' ) {
                fnCallback();
            }
        }
    },
    backToTop: {
        init: function () {
            if ( jQuery( '.js-e-back-to-top' ).length === 1 ) {
                e4.scrollToAnchor.backToTop.bindEvents();
            }
        },
        bindEvents: function () {
            e4.scrollToAnchor.backToTop.isRAF = false;
            jQuery( window ).on( 'scroll', function () {
                if ( e4.scrollToAnchor.backToTop.isRAF === false ) {
                    window.requestAnimationFrame( function () {
                        e4.scrollToAnchor.backToTop.toggleVisibility();
                        e4.scrollToAnchor.backToTop.isRAF = false;
                    } );
                    e4.scrollToAnchor.backToTop.isRAF = true;
                }
            } );
        },
        toggleVisibility: function () {
            var jWindow                              = jQuery( window ),
                intWindowScrollTop                   = jWindow.scrollTop();
            e4.scrollToAnchor.scrollDirectionCurrent = intWindowScrollTop > e4.scrollToAnchor.lastScrollTop ? 'down' : 'up';
            if ( e4.scrollToAnchor.scrollDirectionCurrent !== e4.scrollToAnchor.scrollDirectionNew ) {
                if ( intWindowScrollTop > jWindow.height() * e4.settings.scrollToAnchor.backToTop.revealOffset ) {
                    e4.scrollToAnchor.backToTop.show();
                }
                else {
                    e4.scrollToAnchor.backToTop.hide();
                }
            }
            e4.scrollToAnchor.scrollDirectionLast = e4.scrollToAnchor.scrollDirectionCurrent;
        },
        show: function () {
            jQuery( '.js-e-back-to-top' ).addClass( 'is-visible' );
        },
        hide: function () {
            jQuery( '.js-e-back-to-top' ).removeClass( 'is-visible' );
        }
    }
};

