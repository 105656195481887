e4.cc = {
    init: function () {
        if ( jQuery( '.js-e-cc-list' ).length > 0 ) {
            e4.cc.lists.list.bindEvents();
        }
        if ( jQuery( '.js-e-product-cc-list-form' ).length > 0 ) {
            e4.cc.lists.product.bindEvents();
        }
    },
    lists: {
        list: {
            bindEvents: function () {
                var jList = jQuery( '.js-e-cc-list' );
                jList.on( 'click', '.js-e-cc-list-product-remove', e4.cc.lists.list.removeProduct );
                jList.on( 'click', '.js-e-cc-list-product-add-to-cart', e4.cc.lists.list.addSingleProductToCart );
                jList.on( 'click', '.js-e-cc-list-delete', e4.cc.lists.list.delete );
            },
            removeProduct: function ( event ) {
                event.preventDefault();
                e4.cc.lists.ajax( {
                    url: event.target.href
                }, function () {
                    jQuery( event.target ).closest( '.js-e-cc-list-product' ).slideUp( function () {
                        jQuery( this ).closest( '.js-e-cc-list' ).find( '.js-e-cc-list-product-count' ).html( function ( index, oldhtml ) {
                            return parseInt( oldhtml ) - 1;
                        } );
                    } );
                } );
            },
            addSingleProductToCart: function ( event ) {
                var jProduct = jQuery( event.target ).closest( '.js-e-cc-list-product' );
                jProduct.closest( '.js-e-cc-list-products-form' ).find( '.js-e-cc-list-product' ).not( jProduct ).find( ':input' ).prop( 'disabled', true );
            },
            delete: function ( event ) {
                if ( confirm( jQuery( event.target ).data('confirm-msg') ) === false ) {
                    event.preventDefault();
                }
            }
        },
        product: {
            bindEvents: function () {
                jQuery( '.js-e-product-cc-list-form' ).on( 'submit', function ( event ) {
                    event.preventDefault();
                } ).on( 'change', '.js-e-cc-list', e4.cc.lists.product.toggleList );
            },
            toggleList: function ( event ) {
                var jInput = jQuery( event.target );
                if ( jInput.prop( 'checked' ) === true ) {
                    e4.cc.lists.product.addToList( jInput );
                }
                else {
                    e4.cc.lists.product.removeFromList( jInput );
                }
            },
            addToList: function ( jInput ) {
                jInput.prop( 'disabled', true );
                e4.cc.lists.ajax( {
                    url: jInput.data( 'url-add' ) + '&Redirect=false'
                }, function () {
                    jInput.prop( 'disabled', false );
                } );
            },
            removeFromList: function ( jInput ) {
                jInput.prop( 'disabled', true );
                e4.cc.lists.ajax( {
                    url: jInput.data( 'url-remove' ) + '&Redirect=false'
                }, function () {
                    jInput.prop( 'disabled', false );
                } );
            }
        },
        ajax: function ( objAjaxSettings, fnCallback ) {
            var jqXHR = jQuery.ajax( jQuery.extend( {
                url: location.href,
                method: 'GET',
                dataType: 'html',
                cache: false
            }, objAjaxSettings ) );
            jqXHR.done( function () {
                if ( typeof fnCallback === 'function' ) {
                    fnCallback();
                }
            } );
            jqXHR.fail( function ( data, textStatus, errorThrown ) {
                console.error( data, textStatus, errorThrown );
            } );
        }
    }
};
