e4.product = {
    init: function () {
        if ( jQuery( '.js-e-product-form' ).length > 1 ) {
            e4.product.bindEvents();
            e4.product.setProductFormRedirect();
        }
    },
    bindEvents: function () {
        jQuery( '.js-e-product-form' ).on( 'submit', function ( event ) {
            e4.product.addToCart( event );
        } );
    },
    setProductFormRedirect: function () {
        jQuery( '.js-e-product-form' ).find('input[name="Redirect"]').val('false');
    },
    addToCart: function ( event ) {
        event.preventDefault();
        var arrFormData = jQuery( event.target ).serializeArray(),
            objFormData = {};
        for ( var i = 0; i < arrFormData.length; i++ ) {
            objFormData[ arrFormData[ i ].name ] = arrFormData[ i ].value;
        }
        e4.cart.addProduct( objFormData );
    }
};

