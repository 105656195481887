e4.bootstrap.carousel = {
    init: function () {
        e4.bootstrap.carousel.bindEvents();
    },
    bindEvents: function () {
        //jQuery( document ).on( 'keydown', function ( event ) {
        //console.log(event);
        //event.type ='keydown.bs.carousel';
        //jQuery( this ).trigger( event );
        // jQuery( '.carousel' ).each( function () { jQuery( this ).triggerHandler( event );
        // } );
        // jQuery( '.carousel' ).each( function () {
        //     var jThis = jQuery( this );
        //     if ( e4.util.isInViewport( jThis ) === true ) {
        //         if ( /input|textarea/i.test( event.target.tagName ) ) {
        //             return;
        //         }
        //         switch ( event.which ) {
        //         case ARROW_LEFT_KEYCODE:
        //             event.preventDefault();
        //             jThis.prev();
        //             break;
        //         case ARROW_RIGHT_KEYCODE:
        //             event.preventDefault();
        //             jThis.next();
        //             break;
        //         default:
        //             return;
        //         }
        //     }
        //} );
       jQuery( document ).on( 'keydown', function ( event ) {
            jQuery( '.carousel').each( function () {
                var jThis = jQuery( this );
                if ( e4.util.isInViewport( jThis ) === true ) {
                    if ( /input|textarea/i.test( event.target.tagName ) ) {
                        return;
                    }
                    //console.log( event.which );
                    switch ( event.which ) {
                    case 37:
                        //event.preventDefault();
                        jThis.carousel( 'prev' );
                        break;
                    case 39:
                        //event.preventDefault();
                        jThis.carousel( 'next' );
                        break;
                    default:
                        return;
                    }
                }
            } );
        } );
        jQuery( '.carousel' ).on( 'swipeleft', function () {
            jQuery( this ).carousel( 'next' );
        } ).on( 'swiperight', function () {
            jQuery( this ).carousel( 'prev' );
        } ).find( '.carousel-indicators li' ).on( 'mouseenter', function () {
            jQuery( this ).trigger( 'click' );
        } );
    }
};

